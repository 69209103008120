<template>
  <div class="home">
    <v-card elevation="0" class="background" height="100vh" v-if="clinica.Nombre">
      <!-- nombre de la clinica -->
      <v-card-title class="px-3 ma-0">
        <v-avatar v-if="clinica.Var6" size="100">
          <v-img class="logoa4" contain :src="clinica.Var6"></v-img>
        </v-avatar>
        <div class="mx-4">{{ clinica.Nombre }}</div>
      </v-card-title>
      <!-- tabs con menu de opciones -->
      <v-card class="pa-0 ma-0" color="primary" elevation="0">
        <v-tabs right background-color="background" v-model="tab">
          <v-tab key="reserva">Reservar hora</v-tab>
          <v-tab key="consulta">Consultar hora</v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items v-model="tab">
        <div class="d-flex justify-end background">
          <div class="px-6 d-flex">
            <v-btn class="ma-2" color="primary" outlined text @click="activeHelp = true">
              Necesita ayuda
              <v-icon dark right> mdi-help </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="px-5 my-3 background" v-if="activeHelp">
          <v-card class="rounded-xl" elevation="1" color="background">
            <div class="d-flex pa-3">
              <div class="my-3 primary--text">
                Instrucciones para agendar una cita
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex">
                <v-btn @click="activeShoeVideo = true" color="primary" class="mt-1 mr-3" small>Ver video de
                  ejemplo</v-btn>
                <v-btn icon @click="activeHelp = false"><v-icon>mdi-close</v-icon></v-btn>
              </div>
            </div>
            <div v-if="activeShoeVideo" class="background">
              <div class="background">
                <video width="100%" height="100%" controls>
                  <source
                    src="https://storage.googleapis.com/clivenapp-3909d.appspot.com/imgprofile/Grabacio%CC%81n%20de%20pantalla%202023-03-12%20a%20la(s)%2023.57.11.mov"
                    type="video/mp4" />
                </video>
              </div>
            </div>
            <v-card-text>
              <v-row class="d-flex flex-column pa-0 ma-0">
                <v-col>
                  <div>
                    <div class="primary--text">
                      1. Seleccione servicio y especialidad
                    </div>
                    <p>
                      .- Primero: Seleccionar una especialidad, al seleccionar
                      se desplegará los servicios disponibles <br />
                      .- Segundo: Seleccione una servicio disponible, si no hay
                      resultado, debe probar con otra especialidad, confirme con
                      la clinica el servicio que esta buscando
                    </p>
                  </div>
                </v-col>
                <v-divider class="my-4"></v-divider>
                <v-col>
                  <div>
                    <div class="primary--text">
                      2. Seleccione el profesional, sucursal y la hora
                    </div>
                    <p>
                      .- Primero: Seleccione al profesional, una vez que cargue
                      desplegará la lista con las sucur donde tenga disponible
                      hora <br />
                      .- Segundo: Seleccione la sucursal disponible, si no hay
                      resultados significa que el profesional no tiene horas
                      disponible, debe contactar la clinica <br />
                      .- Tercero: Seleccione el día, puede ver al los próximos
                      días disponibles, una vez seleccionado el día podrá ver
                      las horas <br />
                      .- Cuarto: Seleccione una hora disponible y presione
                      seleccionar, automaticamente avanzar a la siguiente
                      página.
                    </p>
                  </div>
                </v-col>
                <v-divider class="my-4"></v-divider>
                <v-col>
                  <div>
                    <div class="primary--text">
                      3. Ingresar los datos del paciente y agendar
                    </div>
                    <p>
                      .- Primero: Busque al paciente con el correo electrónico,
                      si tiene una ficha en la clinica podrá seleccionar y
                      automaticamente cargará todos sus datos. <br />
                      .- Segundo: Ingresar todos los datos del paciente en caso
                      que esté no sea seleccionado, verifique que los todos los
                      datos estén correctamente ingresados <br />
                      .- Tercero: Verifique que la hora esté correctamente
                      seleccionada al prinicipio de la página, una vez seguro
                      confirmar presionado el boton agendar, esté estará
                      disponible una vez que estén todos los datos ingresados.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-tab-item key="reserva" class="background">
          <!-- contenido reserva de hora -->
          <div class="mx-auto">
            <v-card max-width="1200px" elevation="0" class="mx-auto my-12 rounded-xl background">
              <v-stepper v-model="e1" class="mx-2">
                <!-- titulos del steps -->
                <v-stepper-header>
                  <v-stepper-step editable :complete="e1 > 1" step="1">
                    Servicio
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step editable :complete="e1 > 2" step="2">
                    Profesional y agenda
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step editable step="3"> Paciente </v-stepper-step>
                </v-stepper-header>
                <!-- steps -->
                <v-stepper-items class="my-10">
                  <!-- especialidad y servicio -->
                  <v-stepper-content step="1">
                    <v-card class="mb-12" elevation="0">
                      <v-card-title> Especialidad y Servicio </v-card-title>
                      <v-card-text>
                        <v-select outlined label="Seleccione especialidad" :items="especialidades"
                          v-model="selectEspecialidad" item-text="Nespecialidad"></v-select>
                        <v-select outlined :loading="loadingServicios" v-model="selectServicio"
                          v-if="selectEspecialidad" label="Seleccione servicio" :items="servicios"
                          item-text="Descripcion"></v-select>
                        <div v-else>
                          Seleccione una especilidad para cargar los servicios
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-btn color="primary" :disabled="selectEspecialidad == '' || selectServicio == ''
                      " @click="e1 = 2">
                      Continuar
                    </v-btn>
                  </v-stepper-content>
                  <!-- Profesiónal y agenda -->
                  <v-stepper-content step="2">
                    <!--  -->
                    <div v-if="(selectEspecialidad != '') & (selectServicio != '')">
                      <v-card class="mb-12" elevation="0">
                        <v-card-title> Profesional y sucursal </v-card-title>
                        <v-card-text>
                          <v-select outlined label="Seleccione profesional" :items="doctors" v-model="selectDoctor"
                            item-text="Nombre" :loading="loadingCargaSetAgenda" item-value="Rut"></v-select>
                          <div v-if="selectDoctor">
                            <v-select outlined v-model="selectSucursal" v-if="sucursales.length > 0"
                              label="Seleccione sucursal" :items="sucursales"></v-select>
                          </div>
                          <div v-else>
                            Debe seleccionar un profesional para ver las
                            sucursales disponibles, si al seleccionar no hay
                            resultado significa que no tiene horas disponible y
                            debe intentar con otro profesional.
                          </div>
                          <v-row v-if="showCalendar">
                            <v-col class="d-flex">
                              <v-date-picker locale="es-ES" v-model="selectDay" :allowed-dates="allowedDates"
                                class="mt-3"></v-date-picker>
                              <div class="pa-4">
                                <div>
                                  <div>
                                    <p class="mr-3">Próximos días</p>
                                  </div>

                                  <v-chip-group v-model="selectDay" item-value active-class="primary--text" column>
                                    <v-chip v-for="(tag, index) in agendaFilter" :key="tag.Dia" :value="tag.Dia">
                                      {{ tag.Dia.substring(8, 10) }} del
                                      {{ setMonth(tag.Dia.substring(5, 7)) }}
                                      del {{ tag.Dia.substring(0, 4) }}
                                    </v-chip>
                                  </v-chip-group>
                                </div>
                              </div>
                            </v-col>
                            <v-col>
                              <v-card class="my-2 rounded-xl" elevation="1">
                                <v-row class="pa-0 ma-0">
                                  <v-col col="4">
                                    <div v-if="selectDoctorImg != 'n'">
                                      <v-avatar size="150">
                                        <v-img contain :src="selectDoctorImg"></v-img>
                                      </v-avatar>
                                    </div>
                                    <div v-else>
                                      <v-avatar class="ml-6" color="primary" size="150">
                                        <v-icon size="80" dark>
                                          mdi-account-circle
                                        </v-icon>
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col class="py-3" col="8">
                                    <div class="subtitle-1 text-center primary--text">
                                      {{ selectDoctorName }}
                                    </div>

                                    <div class="text-center">
                                      {{ selectDoctor }}
                                    </div>
                                    <div class="text-center">
                                      {{ selectDoctorEspecialidad }}
                                    </div>
                                    <div v-if="selectProximaHora.fecha">
                                      <div class="title-1 text-center primary--text">
                                        Próxima hora disponible
                                      </div>
                                      <div class="d-flex justify-center my-3">
                                        <v-chip color="primary" outlined small>{{
                                          selectProximaHora.fecha.substring(
                                            8,
                                            10
                                          )
                                        }}
                                          del
                                          {{
                                            setMonth(
                                              selectProximaHora.fecha.substring(
                                                5,
                                                7
                                              )
                                            )
                                          }}
                                          a las
                                          {{ selectProximaHora.tiempo }}</v-chip>
                                      </div>
                                      <div class="title-1 text-center">
                                        Sucursal:
                                        {{ selectProximaHora.sucursal }}
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card>
                              <v-card class="mx-auto" elevation="0">
                                <v-card-title>
                                  <h2 class="text-h4">Horas</h2>
                                </v-card-title>

                                <v-card-text>
                                  Seleccione una hora que se encuentre
                                  disponible
                                </v-card-text>

                                <v-divider class="mx-4"></v-divider>
                                <v-alert v-if="selectDay" type="info" color="primary" outlined>Día {{ selectDay }}
                                </v-alert>
                                <v-alert v-else type="info" color="primary" outlined>Día no seleccionado
                                </v-alert>

                                <v-card-text>
                                  <span class="subheading">Seleccionar</span>
                                  <v-chip-group v-if="!loadingProgramacionDay" v-model="selectHora"
                                    active-class="primary--text text--accent-4" column>
                                    <v-chip v-for="size in sizes" :key="size" :value="size">
                                      {{ size }}
                                    </v-chip>
                                  </v-chip-group>
                                  <v-card-text v-else>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                  </v-card-text>
                                </v-card-text>

                                <v-card-actions>
                                  <v-btn block @click="btnSelectHora" :disabled="selectDay == '' || selectHora == ''
                                    " class="white--text" color="primary">
                                    Seleccionar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                      <v-btn color="primary" :disabled="selectDay == '' || selectHora == ''" @click="e1 = 3">
                        Continue
                      </v-btn>
                    </div>
                    <div v-else>
                      <p>
                        Para seleccionar al profesional debe seleccionar una
                        especialidad y un servicio primero
                      </p>
                    </div>
                  </v-stepper-content>
                  <!-- paciente -->
                  <v-stepper-content step="3">
                    <v-alert color="primary" v-if="selectSearchProgramacion.Id" outlined type="info">Hora seleccionada
                      para el día
                      {{ selectSearchProgramacion.Date.substring(0, 10) }} a las
                      {{ selectSearchProgramacion.Time }} con el profesional
                      {{ selectDoctorName }} en la sucursal
                      {{ selectSucursal }}</v-alert>
                    <v-alert color="primary" v-else outlined type="error">No ha seleccionado una hora</v-alert>
                    <div>
                      <v-form ref="form" v-model="isFormValid" lazy-validation>
                        <v-row class="mt-2">
                          <!-- Sección mascota -->
                          <v-col cols="12" md="6" xl="6" lg="6">
                            <div class="d-flex justify-center mb-5" width="100%">
                              <div>Datos de la mascota</div>
                            </div>
                            <!-- Formulario mascota -->
                            <div>
                              <div>
                                <!-- Buscar cliente existente -->
                                <v-alert type="info" color="primary" class="my-2">Si el paciente tiene una ficha debe
                                  buscarlo,
                                  debido a que ingresar los datos sin buscar
                                  creará una nueva y va a generar una duplicidad
                                  en la ficha de su mascota. Si no encuentra con
                                  su correo, contacte a la clinica para obtener
                                  la información de busqueda</v-alert>
                                <div class="ma-2">
                                  <v-checkbox v-model="activeSearchCustomers"
                                    label="Buscar mascota registrada"></v-checkbox>
                                </div>
                                <!-- Tabla de clientes existentes -->
                                <v-row v-if="activeSearchCustomers" class="ma-0 pa-1 mb-2">
                                  <v-card width="100%" class="pa-3 mb-5 rounded-xl">
                                    <v-card-title class="mt-4 ml-4">
                                      <div>Buscar cliente</div>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <div class="pa-3">
                                      <div class="d-flex">
                                        <v-text-field v-model="searchCliente" append-icon="mdi-magnify"
                                          placeholder="Ingresar correo del propietario" single-line
                                          :rules="[rules.required]" @keyup.enter="btnSearchCliente" hide-details
                                          class="mb-1"></v-text-field>
                                        <v-btn color="primary" outlined class="mt-2 ml-2"
                                          @click="btnSearchCliente">Buscar</v-btn>
                                      </div>
                                      <v-data-table :headers="headers" :footer-props="{
                                        'items-per-page-text':
                                          'Filas por página',
                                      }" :items="clientes" :search="search" :loading="loadingClientes"
                                        loading-text="Buscando... Espere" dense>
                                        <template v-slot:no-data>
                                          <v-alert :value="true">
                                            No hay resultados disponibles
                                          </v-alert>
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                          <div class="actions pa-1">
                                            <v-tooltip left>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" icon v-bind="attrs" v-on="on"
                                                  class="text-capitalize rounded-lg" @click="
                                                    btnSelectCustomerDialog(
                                                      item.Id
                                                    )
                                                    "><v-icon>mdi-check-circle-outline</v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Seleccionar</span>
                                            </v-tooltip>
                                          </div>
                                        </template>
                                      </v-data-table>
                                    </div>
                                  </v-card>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" xs="12" sm="11" md="5" lg="2" xl="2">
                                    <v-dialog v-model="dialogSelect" max-width="300px" class="ma-3" persistent>
                                      <v-card class="rounded-xl">
                                        <div class="pa-3">
                                          <v-card-title class="title-1 text--center">¿Seguro(a) que quiere <br />
                                            asignar a {{ clienteNombre }}
                                          </v-card-title>
                                          <v-card-actions class="d-flex justify-end">
                                            <v-btn color="primary" outlined @click="closeDelete"
                                              class="text-capitalize rounded-lg">Cancelar</v-btn>
                                            <v-btn color="primary" @click="btnSelectCustomer()"
                                              class="text-capitalize rounded-lg ml-3">Confirmar</v-btn>
                                          </v-card-actions>
                                        </div>
                                      </v-card>
                                    </v-dialog>
                                  </v-col>
                                </v-row>
                              </div>
                              <!-- Primera fila -->
                              <div class="d-flex">
                                <v-text-field v-model="cliente.Nombre" class="ma-2" outlined dense
                                  :rules="[rules.required]" :validate-on-blur="false" :error="errorForm"
                                  @keyup.enter="btnCreateCliente" @change="resetErrors" label="Nombre"></v-text-field>
                              </div>
                              <!-- Segunda fila -->
                              <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                <v-col class="pa-0 ma-0">
                                  <v-select v-model="cliente.Sexo" class="ma-2" :rules="[rules.required]"
                                    :validate-on-blur="false" :error="errorForm" @keyup.enter="btnCreateCliente"
                                    @change="resetErrors" required :items="genders" label="Género" outlined
                                    dense></v-select>
                                </v-col>
                                <!-- Fecha de nacimiento -->
                                <v-col class="pa-0 ma-0">
                                  <v-menu ref="menu_pet_birth" v-model="menu_pet_birth" :close-on-content-click="false"
                                    :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="cliente.Nacimiento" outlined label="Fecha de nacimiento"
                                        prepend-icon="mdi-calendar" readonly :rules="[rules.required]" class="ma-2"
                                        v-bind="attrs" v-on="on" dense></v-text-field>
                                    </template>
                                    <v-date-picker locale="es-ES" v-model="cliente.Nacimiento" no-title
                                      :rules="[rules.required]" scrollable>
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="text-capitalize"
                                        @click="menu_pet_birth = false">
                                        Cancelar
                                      </v-btn>
                                      <v-btn text color="primary" @click="
                                        $refs.menu_pet_birth.save(
                                          cliente.Nacimiento
                                        )
                                        ">
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                              </div>
                              <!-- Tercera fila -->
                              <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                <v-autocomplete v-model="cliente.Esp" class="ma-2" :items="especies" item-text="Nombre"
                                  item-value="Nombre" outlined :rules="[rules.required]" dense
                                  label="Especie"></v-autocomplete>
                                <v-autocomplete v-model="cliente.Raza" class="ma-2" :items="razas" item-text="Nombre"
                                  item-value="Nombre" outlined :rules="[rules.required]" dense
                                  label="Raza"></v-autocomplete>
                                <v-text-field v-model="cliente.Color" class="ma-2" :rules="[rules.required]" outlined
                                  dense label="Color"></v-text-field>
                              </div>
                            </div>
                          </v-col>
                          <!-- División vista escritorio -->
                          <v-divider vertical class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
                          <!-- Sección cliente -->
                          <v-col cols="12" md="6" xl="6" lg="6">
                            <!-- División vista móvil -->
                            <v-divider class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none mb-8"></v-divider>
                            <!-- Título de la sección -->
                            <div class="d-flex justify-center mb-5">
                              <div>Datos del propietario</div>
                            </div>
                            <!-- Cuerpo de la sección -->
                            <div>
                              <!-- Formulario de registro -->
                              <div>
                                <!-- Rut cliente -->
                                <div>
                                  <v-alert class="ma-2" v-if="error != ''" border="left" colored-border type="error"
                                    elevation="2">
                                    {{ error }}
                                  </v-alert>
                                  <v-text-field v-model="rut" class="ma-2" name="rut" ref="rut" id="rut"
                                    :rules="[rules.required]" :validate-on-blur="false" :error="errorForm"
                                    @keyup.enter="btnCreateCliente" @change="resetErrors" type="text"
                                    @input="validateRut" outlined dense label="Rut" required autofocus />
                                </div>
                                <div>
                                  <v-text-field v-model="cliente.Amo" class="ma-2" outlined :rules="[rules.required]"
                                    :validate-on-blur="false" :error="errorForm" dense
                                    label="Nombre y apellido"></v-text-field>
                                </div>
                                <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                  <v-text-field v-model="cliente.Direccion" class="ma-2" outlined dense
                                    :rules="[rules.required]" label="Dirección" full-width>
                                  </v-text-field>
                                  <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                    <v-text-field v-model="cliente.Comuna" class="ma-2" outlined dense
                                      :rules="[rules.required]" full-width label="Comuna">
                                    </v-text-field>
                                    <v-text-field v-model="cliente.Ciudad" class="ma-2" outlined dense
                                      :rules="[rules.required]" full-width label="Ciudad">
                                    </v-text-field>
                                  </div>
                                </div>
                                <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                  <v-text-field v-model="cliente.Fono" class="ma-2" outlined dense
                                    label="Teléfono"></v-text-field>
                                  <v-text-field v-model="cliente.Altura" class="ma-2" outlined dense
                                    label="Número celular"></v-text-field>
                                </div>
                                <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                  <v-text-field v-model="cliente.Email" class="ma-2" outlined dense
                                    label="Correo electrónico">
                                  </v-text-field>
                                </div>

                                <!-- Botón acción agendar  -->
                                <div class="d-flex ma-2 justify-end" width="100%">
                                  <v-btn color="primary" :loading="activeLoadingCreateNuevaProgramacion
                                    " :disabled="selectSearchProgramacion.Id == '' ||
                                      cliente.Nombre == '' ||
                                      rut == '' ||
                                      cliente.Amo == '' ||
                                      cliente.Direccion == '' ||
                                      cliente.Nacimiento == '' ||
                                      cliente.Sexo == '' ||
                                      cliente.Email == ''
                                      " class="text-capitalize rounded-lg" @click="btnAgendarHora">Agendar</v-btn>
                                </div>
                                <v-alert class="my-4" type="info" outlined color="primary">
                                  El boton estará disponible al escoger una hora
                                  y completar los datos del paciente. Una vez
                                  realizado el agendamiento recibira
                                  notificaciones via correo electrónico, la cual
                                  debe aceptar para que quede confirmada la
                                  cita.</v-alert>
                              </div>
                              <!-- dialogo de creacion de reserva con información -->
                              <v-dialog v-model="activeShowAlertCreate" max-width="450px" persistent class="pa-5">
                                <v-card class="py-7">
                                  <v-card-text>
                                    <div>
                                      <v-alert type="info" color="primary" outlined>Consideración importante</v-alert>
                                    </div>
                                    <div class="d-flex justify-center">
                                      <v-avatar tile size="150">
                                        <v-img contain sizes="200" src="/mail.svg"></v-img>
                                      </v-avatar>
                                    </div>
                                    <div>
                                      Se ha creado la reserva de la agenda, sin
                                      embargo está no está en un estado
                                      confirmado hasta que confirme a través del
                                      email enviado a su correo, debe revisar
                                      todas las bandejas en casos que pueda
                                      llegar a spam o correos no deseados.
                                    </div>
                                  </v-card-text>
                                  <v-card-action class="d-flex justify-center">
                                    <v-btn @click="btnExit" color="primary">Inicio</v-btn>
                                  </v-card-action>
                                </v-card>
                              </v-dialog>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item key="consulta" class="background">
          <div class="mx-auto">
            <v-card max-width="1200px" elevation="1" class="mx-auto my-12 pa-6 rounded-xl white">
              <v-card-title>Consultar horas</v-card-title>
              <!-- contenido de busqueda de horas y acciones -->
              <v-card-text>
                <v-alert type="info" color="primary" outlined class="my-2">Puede consultar las horas agendas con el
                  correo
                  eléctronico,
                  donde podrá confirmar y anular las horas agendas, además de
                  conocer la información de la cita.</v-alert>
                <div class="my-3 d-flex">
                  <v-text-field outlined label="Correo electrónico" v-model="emailConsulta" :rules="emailRules">
                  </v-text-field>
                  <v-btn class="mt-2 ml-3" color="primary" @click="btngetAgendavetByEmail"
                    :loading="loadingAgendavetConsulta">Buscar</v-btn>
                </div>
                <!-- consutla hora -->
                <div v-if="searchQuery">
                  <div v-if="agendavetConsulta.length > 0">
                    <v-card v-for="agvc in agendavetConsulta" :key="agvc.Id" color="white" class="my-4" elevation="1"
                      outlined>
                      <v-row class="pa-2 ma-0">
                        <h4 class="primary--text">
                          Fecha: {{ agvc.Fecha }} a las {{ agvc.Hora }}
                        </h4>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="pa-2">
                        <v-col>
                          <p>
                            Máscota {{ agvc.Mascota }}
                            <br />
                            Servicio: {{ agvc.Servicio }}
                          </p>
                        </v-col>
                        <v-col>
                          Lugar {{ agvc.Sede }}
                          <br />
                          Profesional {{ agvc.Nombredoc }}
                        </v-col>
                        <v-col class="d-flex align-center">
                          <div v-if="agvc.Estado == 0">
                            <v-chip color="primary" outlined>Pendiente</v-chip>
                          </div>
                          <div v-if="agvc.Estado == 1">
                            <v-chip color="primary" outlined>Confirmada</v-chip>
                          </div>
                          <div v-if="agvc.Estado == 2">
                            <v-chip color="primary" outlined>Anulada</v-chip>
                          </div>
                        </v-col>
                        <v-col class="d-flex align-center">
                          <div v-if="agvc.Estado == 0">
                            <v-btn color="primary" outlined @click="btnAgendavetConfirmarById(agvc.Id)"
                              :loading="loadingConfirmacionAgenda">Confirmar</v-btn>
                          </div>
                          <div v-if="agvc.Estado == 0 || agvc.Estado == 1">
                            <v-btn class="mx-3" color="red" outlined
                              @click="btnActiveAnulacionHora(agvc.Id, agvc)">Anular</v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                  <v-alert v-else>No se encontraron resultado con su busqueda</v-alert>
                </div>
                <!-- anulación de hora -->
                <v-dialog v-model="activeAnularHora" persistent max-width="450">
                  <v-card class="pa-3 ">
                    <v-card-title class="text-h5">
                      Anulación de hora agendada
                    </v-card-title>
                    <v-alert type="info" color="primary" outlined class="mx-3">Hora {{ selectAgendaById.Fecha }} a las
                      {{ selectAgendaById.Hora }} para
                      {{ selectAgendaById.Mascota }} en sucursal
                      {{ selectAgendaById.Sede }}</v-alert>
                    <v-card-text>Realice la anulación si se encuentra seguro que no podra
                      asistir a la cita, recibira un correo electrónico donde
                      deberá confirmar la anulación.</v-card-text>
                    <div class="d-flex justify-center">
                      <v-avatar tile size="150">
                        <v-img contain sizes="200" src="/mail.svg"></v-img>
                      </v-avatar>
                    </div>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="btnCancelarAnulacionAgenda">
                        Cancelar
                      </v-btn>
                      <v-btn color="red" outlined :loading="loadingAnulacionAgenda" @click="btnConfirmaAnulacionAgenda">
                        Solicitar anulación
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- notificación de anulación -->
                <v-snackbar v-model="successCreateAnulacionAgenda" color="primary">
                  Notificación enviada al correo
                </v-snackbar>
                <!-- notifcacion de confirmaicon -->
                <v-snackbar v-model="successCreateConfirmacionAgenda" color="primary">
                  Confirmación exitosa
                </v-snackbar>
              </v-card-text>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- error sin token -->
    <v-card v-else elevation="0" class="mx-5" height="100vh" width="100%">
      <v-overlay absolute opacity="2" color="background">
        <v-progress-circular indeterminate :size="200" color="primary" class="">
          Cargando</v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
var el = [];
function loadDate(payload) {
  el = payload;
}
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      e1: 1,
      tab: "",
      selectEspecialidad: "",
      selectServicio: "",
      selectDoctor: "",
      showCalendar: false,
      isFormValid: false,
      genders: ["Macho", "Macho castrado", "Hembra", "Hembra esterilizada"],
      cliente: {
        Fichan: 0,
        Nombre: "",
        Amo: "",
        Fono: "",
        Nacimiento: "",
        Raza: "",
        Direccion: "",
        Comuna: "",
        Ciudad: "",
        Email: "",
        Sexo: "",
        Fotoactiva: "SI",
        Madre: "n",
        Padre: "n",
        Peso: "n",
        Altura: "",
        Edad: "Plan",
        Reg: "",
        Color: "",
        Esp: "",
        Insc: "N",
        Microchip: "0",
        Fichaant: "no registra",
        Clinica: "01/01/2001",
        Drtratante: "Cliente vigente",
        Peluqueria: "no registra",
        Rut: "",
        Id: 0,
      },
      nuevaAgenda: {
        Id: 0,
        Rutprofesional: "",
        Fecha: "",
        Hora: "",
        Estado: 0,
        Rutcliente: "",
        Mascota: "",
        Tomohora: "",
        Atendido: "no",
        Especialidad: "",
        Servicio: "",
        Razon_cancelacion: "no",
        Programacion_id: "",
        Sede: "",
        FechaCancelacion: "",
        TipoPago: "",
        Ficha: 0,
      },
      dialogSelect: false,
      errorForm: false,
      errorMessages: "",
      selectCustomerId: "",
      selection: "",
      error: "",
      rut: "",
      searchCliente: "",
      search: "",
      clienteNombre: "",
      selectHora: "",
      menu_pet_birth: false,
      rules: {
        required: (value) => (value && Boolean(value)) || "Campo requerido",
      },
      sizes: [],
      activeSearchCustomers: false,
      headers: [
        { text: "Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Nombre", value: "Amo" },
        { align: "start", text: "Seleccionar", value: "action" },
      ],
      agenda: [],
      diasAgenda: [],
      agendaFilter: [],
      diasFilterAgendaSucursal: [],
      sucursales: [],
      selectSucursal: "",
      loadingCargaSetAgenda: false,
      selectDay: "",
      selectSearchProgramacion: {
        Id: "",
      },
      selectDoctorName: "",
      activeLoadingCreateNuevaProgramacion: false,
      email: "",
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Ingresar email valido",
      ],
      emailConsulta: "",
      emailConfirma: "",
      searchQuery: false,
      searchQueryConfirma: false,
      activeAnularHora: false,
      selectAgendaIdAnulacion: 0,
      selectAgendaById: {},
      activeShowAlertCreate: false,
      selectDoctorImg: "",
      selectDoctorEspecialidad: "",
      selectProximaHora: {
        programacion: "",
        sucursal: "",
        tiempo: "",
        fecha: "",
      },
      activeHelp: false,
      activeShoeVideo: false,
    };
  },
  watch: {
    selectEspecialidad: function (val) {
      this.showCalendar = false;
      this.selectDay = "";
      this.selectHora = "";
      this.selectServicio = "";
      this.selectSucursal = "";
      this.selectSearchProgramacion = {
        Id: "",
      };
      if (val) {
        this.getServiciosByEspecialidad({
          token: this.$route.query.token,
          especialidad: this.selectEspecialidad,
        });
        this.getDoctorsByEspecialidad({
          token: this.$route.query.token,
          especialidad: this.selectEspecialidad,
        });
      }
    },
    selectDoctor: async function (val) {
      if (val) {
        this.loadingCargaSetAgenda = true;
        this.showCalendar = false;
        this.selectDay = "";
        this.selectHora = "";
        this.selectSearchProgramacion = {
          Id: "",
        };
        this.sizes = [];
        this.agendaFilter = [];
        this.agenda = [];
        this.selectSucursal = "";
        let searchDoc = this.doctors.find((item) => item.Rut == val);
        this.selectDoctorName = searchDoc.Nombre;
        this.selectDoctorImg = searchDoc.Imagen;
        this.selectDoctorEspecialidad = searchDoc.Especialidad;
        await this.getProgrmacionByRutDateAgenda({
          token: this.$route.query.token,
          rut: val,
          start_date: new Date().toISOString().substring(0, 10),
        });
        this.loadingCargaSetAgenda = false;
      }
    },
    rut: function (val) {
      this.rut = this.cleanRut(this.rut);
      let len = this.rut.length - 1;
      if (len > 3) {
        let dv = this.rut[len];
        let beforeDv = this.rut
          .substring(0, len)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        this.rut = beforeDv + "-" + dv;
      }
    },
    selectSucursal: function (val) {
      this.selectDay = "";
      this.selectHora = "";
      this.sizes = [];
      loadDate([]);
      this.selectSearchProgramacion = {
        Id: "",
      };
      if (val && this.selectDoctor) {
        // loadDate(this.diasAgenda);
        this.agendaFilter = this.agenda.filter((item) => item.Sucursal == val);
        let setday = [];
        this.agendaFilter.forEach((element) => {
          setday.push(element.Dia);
        });
        loadDate(setday);
        this.showCalendar = true;
      }
    },
    programacionDay: async function (val) {
      let array_date = [];
      this.selectProximaHora = {
        programacion: "",
        sucursal: "",
        tiempo: "",
        fecha: "",
      };
      val.forEach((dateagenda) => {
        if (dateagenda.State == "Libre") {
          let newdate = new Date(
            dateagenda.Date.substring(0, 10) +
            "T" +
            dateagenda.Time +
            ":00.000Z"
          );
          if (newdate > new Date()) {
            array_date.push({
              programacion: newdate,
              sucursal: dateagenda.Sucursal,
              tiempo: dateagenda.Time,
              fecha: dateagenda.Date,
            });
          }
        }
      });

      let array_sort = array_date.sort(function (a, b) {
        return a.fecha - b.fecha;
      });

      this.selectProximaHora = array_sort[0];
      await val.forEach((element) => {
        let result_sucursal_index = this.sucursales.findIndex(
          (item) => item == element.Sucursal
        );
        if (result_sucursal_index == -1) {
          this.sucursales.push(element.Sucursal);
        }
        let result_index = this.agenda.findIndex(
          (item) =>
            item.Dia == element.Date.substring(0, 10) &&
            item.Sucursal == element.Sucursal
        );

        if (element.State == "Libre") {
          if (result_index == -1) {

            this.agenda.push({
              Dia: element.Date.substring(0, 10),
              Sucursal: element.Sucursal,
              Horas: [element.Time],
            });
          } else {

            if (!this.agenda[result_index].Horas.includes(element.Time)) {
              this.agenda[result_index].Horas.push(element.Time);
            }
          }
        }
      });
      this.loadingCargaSetAgenda = false;
    },
    programacion: async function (val) {
      let array_date = [];
      this.selectProximaHora = {
        programacion: "",
        sucursal: "",
        tiempo: "",
        fecha: "",
      };
      val.forEach((dateagenda) => {
        if (dateagenda.State == "Libre") {
          let newdate = new Date(
            dateagenda.Date.substring(0, 10) +
            "T" +
            dateagenda.Time +
            ":00.000Z"
          );
          if (newdate > new Date()) {
            array_date.push({
              programacion: newdate,
              sucursal: dateagenda.Sucursal,
              tiempo: dateagenda.Time,
              fecha: dateagenda.Date,
            });
          }
        }
      });

      let array_sort = array_date.sort(function (a, b) {
        return a.fecha - b.fecha;
      });

      this.selectProximaHora = array_sort[0];
      await val.forEach((element) => {
        let result_sucursal_index = this.sucursales.findIndex(
          (item) => item == element.Sucursal
        );
        if (result_sucursal_index == -1) {
          this.sucursales.push(element.Sucursal);
        }
        let result_index = this.agenda.findIndex(
          (item) =>
            item.Dia == element.Date.substring(0, 10) &&
            item.Sucursal == element.Sucursal
        );
        if (element.State == "Libre") {
          if (result_index == -1) {
            this.agenda.push({
              Dia: element.Date.substring(0, 10),
              Sucursal: element.Sucursal,
              Horas: [element.Time],
            });
          } else {
            this.agenda[result_index].Horas.push(element.Time);
          }
        }
      });
      this.loadingCargaSetAgenda = false;
    },
    selectDay: async function (val) {
      this.selectHora = "";
      this.selectSearchProgramacion = {
        Id: "",
      };
      if (val && this.selectSucursal) {
        await this.getProgrmacionByRutDateAgendaAndDay({
          token: this.$route.query.token,
          rut: this.selectDoctor,
          start_date: val,
        });
        let search = this.agendaFilter.find(
          (item) => item.Sucursal == this.selectSucursal && item.Dia == val
        );
        //las horas
        this.sizes = search.Horas;
      }
    },
    agendavetConsulta: function (val) {
      this.searchQuery = true;
    },
    successCreateAnulacionAgenda: function (val) {
      if (val) {
        this.activeAnularHora = false;
      }
    },
    successCreateNuevaAgenda: function (val) {
      if (val) {
        this.activeLoadingCreateNuevaProgramacion = false;
        this.activeShowAlertCreate = true;
        this.showCalendar = false;
        this.selectDay = "";
        this.selectHora = "";
        this.selectServicio = "";
        this.selectDoctor = "";
        this.agenda = "";
        this.agendaFilter = "";

        this.selectEspecialidad = "";
        this.selectSearchProgramacion = {
          Id: "",
        };
        this.cliente = {
          Fichan: 0,
          Nombre: "",
          Amo: "",
          Fono: "",
          Nacimiento: "",
          Raza: "",
          Direccion: "",
          Comuna: "",
          Ciudad: "",
          Email: "",
          Sexo: "",
          Fotoactiva: "SI",
          Madre: "n",
          Padre: "n",
          Peso: "n",
          Altura: "",
          Edad: "Plan",
          Reg: "",
          Color: "",
          Esp: "",
          Insc: "N",
          Microchip: "0",
          Fichaant: "no registra",
          Clinica: "01/01/2001",
          Drtratante: "Cliente vigente",
          Peluqueria: "no registra",
          Rut: "",
          Id: 0,
        };
      }
    },
  },
  computed: {
    ...mapState([
      "clinica",
      "especialidades",
      "loadingServicios",
      "servicios",
      "doctors",
      "especies",
      "razas",
      "clientes",
      "loadingClientes",
      "programacion",
      "successCreateNuevaAgenda",
      "agendavetConsulta",
      "loadingAgendavetConsulta",
      "agendavetConfirma",
      "loadingAgendavetConfirma",
      "loadingConfirmacionAgenda",
      "loadingAnulacionAgenda",
      "successCreateAnulacionAgenda",
      "successCreateConfirmacionAgenda",
      "programacionDay",
      "loadingProgramacionDay"
    ]),
  },
  methods: {
    ...mapActions([
      "getClinica",
      "getServiciosByEspecialidad",
      "getDoctorsByEspecialidad",
      "getClientesByEmail",
      "getProgrmacionByRutDateAgenda",
      "getProgrmacionByRutDateAgendaAndDay",
      "postProgramacionAgenda",
      "getAgendavetByEmail",
      "getAgendavetByStatusEmail",
      "getConfirmacionAgendaById",
      "getAnulacionAgendaById",
    ]),
    allowedDates: (val) => el.indexOf(val) !== -1,
    loadClinica() {
      if (this.$route.query.token) {
        this.getClinica({ token: this.$route.query.token });
      } else {
        this.clinica.Nombre = undefined;
      }
    },
    btnCreateCliente() {
      if (this.$refs.form.validate()) {
        // this.activeUploadingCreateCustomer = true;
        // this.cliente.rut = this.rut.replaceAll(".", "");
        // this.cliente.reg = this.cliente.email;
        // var date_register_set = new Date(this.cliente.clinica);
        // var day = date_register_set.getDay();
        // var month = date_register_set.getMonth() + 1;
        // var year = date_register_set.getFullYear();
        // this.cliente.clinica = day + "/" + month + "/" + year;
      }
    },
    cleanRut(value) {
      return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
    },
    validateRut() {
      let rut = this.cleanRut(this.rut);
      let digito_verificador = this.rut.substr(-1);

      rut = rut.substr(0, rut.length - 1);

      let serie = 0;
      let producto = 0;

      do {
        producto += (rut % 10) * ((serie % 6) + 2);
        serie++;
      } while ((rut = Math.floor(rut / 10)));

      let resto = producto % 11;
      let resultado = 11 - resto;

      if (resultado == 11) {
        resultado = 0;
      } else if (resultado == 10) {
        resultado = "K";
      }

      if (digito_verificador != resultado) {
        this.errorInRut();
      } else {
        this.error = "";
      }
    },
    errorInRut() {
      this.error = "RUT Inválido";
      this.$refs.rut.focus();
    },
    btnSearchCliente() {
      this.getClientesByEmail({
        token: this.$route.query.token,
        email: this.searchCliente,
      });
    },
    btnSelectCustomerDialog(id) {
      this.selectCustomerId = id;
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );

      this.clienteNombre = search.Nombre;

      this.dialogSelect = true;
    },
    btnSelectCustomer() {
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );

      this.cliente = search;
      this.rut = search.Rut;

      this.dialogSelect = false;
      this.selectCustomerId = "";
      this.selectCustomer = true;
      this.activeSearchCustomers = false;
    },
    btnSelectHora() {
      let searchProgramacion = this.programacionDay.find(
        (item) =>
          item.Sucursal == this.selectSucursal &&
          item.Date.substring(0, 10) == this.selectDay &&
          item.Time == this.selectHora
      );

      this.selectSearchProgramacion = searchProgramacion;
      let searchDoctor = this.doctors.find(
        (item) => item.Rut == this.selectDoctor
      );
      this.selectDoctorName = searchDoctor.Nombre;

      this.e1 = 3;
    },
    btnAgendarHora() {
      this.activeLoadingCreateNuevaProgramacion = true;
      this.cliente.Rut = this.rut.replaceAll(".", "");
      this.cliente.Reg = this.cliente.Email;
      this.nuevaAgenda.Rutprofesional = this.selectDoctor;
      this.nuevaAgenda.Fecha = this.selectSearchProgramacion.Date.substring(
        0,
        10
      );
      this.nuevaAgenda.Hora = this.selectSearchProgramacion.Time;
      this.nuevaAgenda.Estado = 0;
      this.nuevaAgenda.Rutcliente = this.cliente.Rut;
      this.nuevaAgenda.Mascota = this.cliente.Nombre;
      this.nuevaAgenda.Tomohora = new Date().toISOString().substring(0, 10);
      this.nuevaAgenda.Atendido = "no";
      this.nuevaAgenda.Especialidad = this.selectEspecialidad;
      this.nuevaAgenda.Servicio = this.selectServicio;
      this.nuevaAgenda.Razon_cancelacion = "no";
      this.nuevaAgenda.Programacion_id = this.selectSearchProgramacion.Id;
      this.nuevaAgenda.Sede = this.selectSucursal;
      this.nuevaAgenda.TipoPago = "clinica";
      this.nuevaAgenda.Ficha = this.cliente.Fichan;
      let programacionAgenda = {
        agenda: this.nuevaAgenda,
        cliente: this.cliente,
        nombredoc: this.selectDoctorName,
      };
      this.postProgramacionAgenda({
        programacionAgenda: programacionAgenda,
        token: this.$route.query.token,
      });
    },
    btngetAgendavetByEmail() {
      this.getAgendavetByEmail({
        fecha: new Date().toISOString().substring(0, 10),
        email: this.emailConsulta,
      });
    },
    btngetAgendavetByStatusEmail() {
      this.getAgendavetByStatusEmail({
        fecha: new Date().toISOString().substring(0, 10),
        email: this.emailConfirma,
        estado: "pendiente",
      });
    },
    btnAgendavetConfirmarById(Id) {
      this.getConfirmacionAgendaById({ id: Id, email: this.emailConsulta });
    },
    btnActiveAnulacionHora(id, agenda) {
      this.activeAnularHora = true;
      this.selectAgendaIdAnulacion = id;
      this.selectAgendaById = agenda;
    },
    btnConfirmaAnulacionAgenda() {

      this.getAnulacionAgendaById({
        id: this.selectAgendaIdAnulacion,
        email: this.emailConsulta,
      });
    },
    btnCancelarAnulacionAgenda() {
      this.activeAnularHora = false;
      this.selectAgendaIdAnulacion = 0;
      this.selectAgendaById = {};
    },
    btnExit() {
      window.location.reload();
    },
    setMonth(month) {
      let setmonth = "";
      switch (month) {
        case "01":
          setmonth = "Enero";
          break;
        case "02":
          setmonth = "Febrero";
          break;
        case "03":
          setmonth = "Marzo";
          break;
        case "04":
          setmonth = "Abril";
          break;
        case "05":
          setmonth = "Mayo";
          break;
        case "06":
          setmonth = "Junio";
          break;
        case "07":
          setmonth = "Julio";
          break;
        case "08":
          setmonth = "Agosto";
          break;
        case "09":
          setmonth = "Septiembre";
          break;
        case "10":
          setmonth = "Octubre";
          break;
        case "11":
          setmonth = "Noviembre";
          break;
        case "12":
          setmonth = "Diciembre";
          break;
      }
      return setmonth;
    },
  },
  created() {
    this.loadClinica();
  },
};
</script>
<style>
/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}

.logoa4 {
  object-fit: contain;
}
</style>